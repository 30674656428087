import React from "react";
import { TextField, InputLabelProps, IconButton, MenuItem } from "@material-ui/core";
import { FormInputPropertiesInterface } from "./FormInputProperties";
import { Option } from "../../SelectOptions";
import { Clear } from "@material-ui/icons";

interface BasicInputProps {
    inputProps: FormInputPropertiesInterface;
    error?: boolean;
    value?: string;
    onInputChange(inputInfo: InputInfo): void;
    options: Option[]
}

class BasicInputState {
    other: boolean = false;
    value: string = '';
}

export class InputInfo {
    name: string = '';
    value: string = '';
    label: string = '';
}

export class OtherSelect extends React.Component<BasicInputProps, BasicInputState> {

    protected inputInfo: InputInfo = new InputInfo();
    protected valList: string[] = [''];

    constructor(props: BasicInputProps) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleClearValue = this.handleClearValue.bind(this);
        this.handleChange = this.handleChange.bind(this);

        let state = new BasicInputState();

        for (var i in props.options) {
            this.valList.push(props.options[i].value);
        }
        if (props.value && this.valList.indexOf(props.value) < 0) {
            state.other = true;
        }

        this.state = state;
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {

        this.inputInfo.name = this.props.inputProps.name;
        this.inputInfo.value = event.currentTarget.value;
        this.inputInfo.label = this.props.inputProps.label;

        this.props.onInputChange(this.inputInfo);

    };


    handleSelectChange(event: React.ChangeEvent<HTMLInputElement>) {

        if (event.target.value === 'Other') {
            this.inputInfo.value = '';
            this.setState({
                other: true,
                value: ''
            });
        } else {

            this.inputInfo.value = event.target.value;
            this.setState({
                value: this.inputInfo.value
            })

        }

        this.inputInfo.name = this.props.inputProps.name;
        this.inputInfo.label = this.props.inputProps.label;
        this.props.onInputChange(this.inputInfo);
    };

    handleClearValue() {

        this.inputInfo.name = this.props.inputProps.name;
        this.inputInfo.value = '';
        this.inputInfo.label = this.props.inputProps.label;

        this.setState({
            value: this.inputInfo.value,
            other: false
        });

        this.props.onInputChange(this.inputInfo);
    }

    render() {

        let inputLabelProps: InputLabelProps = {};

        inputLabelProps.shrink = true;

        let value = this.props.value;

        let other = this.state.other;
        if (!other && this.props.value && this.valList.indexOf(this.props.value) < 0) {
            other = true;
        }

        return <React.Fragment>

            {other ?
                <TextField
                    autoFocus={true}
                    value={value || ''}
                    label={this.props.inputProps.label}
                    fullWidth
                    error={this.props.error ? true : false}
                    type='text'
                    required={this.props.inputProps.required}
                    placeholder={this.props.inputProps.placeholder}
                    InputLabelProps={inputLabelProps}
                    variant="outlined"
                    onChange={this.handleChange}
                    InputProps={
                        {
                            endAdornment: <IconButton
                                onClick={this.handleClearValue}>
                                <Clear></Clear>
                            </IconButton>
                        }
                    }
                >
                </TextField>
                :
                <TextField
                    onChange={this.handleSelectChange}
                    label={this.props.inputProps.label}
                    value={value || ''}
                    variant="outlined"
                    fullWidth
                    error={this.props.error ? true : false}
                    required={this.props.inputProps.required}
                    select
                    placeholder={this.props.inputProps.placeholder}
                    InputLabelProps={inputLabelProps}
                >
                    <MenuItem value=''>
                        Select One
                        </MenuItem>
                    {this.props.options.map(o =>
                        <MenuItem key={o.value} value={o.value}>
                            {o.name}
                        </MenuItem>
                    )}
                    <MenuItem value='Other'>
                        Other
                    </MenuItem>
                </TextField>
            }


        </React.Fragment >;
    }
}

export default OtherSelect;