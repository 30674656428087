import React from 'react';
import { AbstractBasicInput, BasicInputProps, BasicInputState } from "../AbstractBasicInput";
import { InputLabelProps, TextField } from "@material-ui/core";
import { isMobile } from 'react-device-detect';
import { format, parse } from 'date-fns';
import _ from 'lodash';

import validator from 'validator';
import ReactInputMask from 'react-input-mask';

export class NativeDatepicker extends AbstractBasicInput<BasicInputProps, BasicInputState> {


    cleanInputBeforeSendingOut(val: string) {

        if (isMobile) {
            return val;
        } else {

            if (val.length === 10 && val.split('/').length === 3) { //old format

                if (validator.toDate(val) !== null) {

                    return format(parse(val, "mm/dd/yyyy", new Date()), "yyyy-mm-dd");
                }


            }

            return val;
        }

    }

    formatValueBeforeDisplaying(val: string) {

        if (isMobile) {
            return val;


        } else {

            if (val.length === 10 && val.split('-').length === 3) { //old format

                return format(parse(val, "yyyy-mm-dd", new Date()), "mm/dd/yyyy");

            } else {
                return val;
            }

        }

    }

    render() {

        let inputLabelProps: InputLabelProps = {};

        inputLabelProps.shrink = true;


        let variant: "filled" | "outlined" | "standard" | undefined = "outlined";
        if (typeof this.props.textFieldVariant !== 'undefined' && this.props.textFieldVariant !== null) {
            variant = this.props.textFieldVariant;
        }

        let inputProps2 = _.clone(this.props.inputProps);

        let responsive = false && isMobile;
        inputProps2.type = responsive ? "date" : "text";

        let val: string | Date = this.formatValueBeforeDisplaying(this.props.value || '');

        if (responsive) {

            return <React.Fragment>

                <TextField
                    name={this.props.inputProps.name}
                    label={this.props.inputProps.label}
                    fullWidth
                    error={this.props.error ? true : false}
                    type="text"
                    onChange={this.handleChange}
                    required={this.props.inputProps.required}
                    placeholder={this.props.inputProps.placeholder}
                    variant={variant}
                    value={val}
                    InputLabelProps={inputLabelProps}
                    InputProps={{ inputProps: inputProps2, inputMode: this.props.inputProps.inputMode }}
                    autoFocus={typeof this.props.formOrder !== 'undefined' ? this.props.formOrder === 1 ? true : false : false}
                />

            </React.Fragment>;

        } else {

            return <React.Fragment>
                <ReactInputMask
                    mask='99/99/9999'
                    value={val}
                    onChange={this.handleChange}>
                    {() => <TextField
                        name={this.props.inputProps.name}
                        label={this.props.inputProps.label}
                        fullWidth
                        error={this.props.error ? true : false}
                        type="text"
                        required={this.props.inputProps.required}
                        placeholder={this.props.inputProps.placeholder}
                        variant={variant}
                        InputLabelProps={inputLabelProps}
                        InputProps={{ inputProps: inputProps2, inputMode: this.props.inputProps.inputMode }}
                        autoFocus={typeof this.props.formOrder !== 'undefined' ? this.props.formOrder === 1 ? true : false : false}
                    />}
                </ReactInputMask>


            </React.Fragment>;

        }

    }

}

export default NativeDatepicker;