import { AbstractBasicInput, BasicInputProps, BasicInputState } from "../AbstractBasicInput";
import StringUtils from "../../../StringUtils";
import { InputLabelProps, TextField, InputAdornment } from "@material-ui/core";
import React from "react";
import _ from "lodash";

export interface CurrencyInputProps extends BasicInputProps {

    muted?: boolean;
}

export class CurrencyInput extends AbstractBasicInput<CurrencyInputProps, BasicInputState> {

    cleanInputBeforeSendingOut(val: string): string {
        return StringUtils.onlyNumbers(val, true);
    }

    formatValueBeforeDisplaying(val: string) {

        if (val === '') {
            return '';
        }

        val = StringUtils.onlyNumbers(val, true);

        var formatted = new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2
        }).format(parseFloat(val));

        if (val.charAt(val.length - 1) === '.') {
            return formatted + '.';
        } else {
            return formatted;
        }

    }

    render() {

        let inputLabelProps: InputLabelProps = {};
        // inputLabelProps.shrink = true;

        let val: string = this.formatValueBeforeDisplaying(this.props.value || '');

        let ip = _.clone(this.props.inputProps);
        ip.type = 'text';

        return <React.Fragment>

            <TextField
                name={this.props.inputProps.name}
                label={!this.props.muted ? this.props.inputProps.label : ''}
                fullWidth
                error={this.props.error ? true : false}
                type="text"
                onChange={this.handleChange}
                required={this.props.inputProps.required}
                placeholder={this.props.inputProps.placeholder}
                value={val}
                InputLabelProps={inputLabelProps}
                InputProps={{
                    inputProps: ip,
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputMode: "numeric"
                }}
                variant="outlined"
                inputMode="numeric"
            />

        </React.Fragment>;
    }

}

export default CurrencyInput;