import React from 'react';
import { CssBaseline, Theme, createStyles, Paper, withStyles, WithStyles, BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import Header from './lib/common/Header';
import FundingForm from './lib/forms/merchant-form/FundingForm';
import { Notifier } from './lib/util/ui/dialog/notifier/Notifier';
import WindowUtils from './lib/util/WindowUtils';
import Loader from './lib/util/ui/dialog/loader/Loader';
import PPPFundingForm from './lib/forms/ppp/PPPFundingForm';
import { ClientAppConfig } from './lib/util/AppUtil';
import { Alert } from '@material-ui/lab';
import InviteOwnerForm from './lib/forms/merchant-form/components/owner/InviteOwnerForm';
import { cookieService } from './lib/util/CookieUtils';
import BankConnectForm from './lib/forms/bank-connect/BankConnectForm';

import AppMetaUtils from "./lib/util/AppMetaUtils";
import {Helmet} from "react-helmet";


const styles = (theme: Theme) => createStyles({

  layout: {
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '0',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values.md,
      marginLeft: 'auto',
      padding: theme.spacing(1),
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  footer: {

    paddingTop: '10px',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    position: 'fixed',
    bottom: '0',
    width: '100%'
  },
  footerToolbar: {

  }
});


interface AppProps extends WithStyles<typeof styles> {
  clientAppConfig: ClientAppConfig;
}

class AppState {
  formToken: string = 'new';
  noHeader: boolean = false;
  loading: boolean = false;
}

class App extends React.Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    let state = new AppState();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('t');
    const embed = params.get('embed');

    if (embed === '1') {
      state.noHeader = true;
    }

    if (token) {
      state.formToken = token;
    } else {
      let cTok = cookieService.get(props.clientAppConfig.repURL);
      if (cTok) {
        state.formToken = cTok;
      }
    }

    this.state = state;
    this.handleFormTokenUpdate = this.handleFormTokenUpdate.bind(this);
    this.handleFooterClick = this.handleFooterClick.bind(this);
    this.storeTokens = this.storeTokens.bind(this);
    this.invalidateTokens = this.invalidateTokens.bind(this);
  }

  storeTokens(formToken: string) {
    cookieService.set(this.props.clientAppConfig.repURL, formToken);
    WindowUtils.ensureParams([{
      key: 't',
      val: formToken
    }]);
  }

  invalidateTokens() {
    cookieService.remove(this.props.clientAppConfig.repURL);
    WindowUtils.clearParams(['t']);
  }

  handleFormTokenUpdate(token: string) {
    if (this.state.formToken === token) {
      return;
    }

    this.setState({
      formToken: token,
      loading: true
    });

    let appMeta = WindowUtils.getParamsLike('customParam_');

    if (Object.keys(appMeta).length > 0) {

      AppMetaUtils.saveAppMeta(token, appMeta).then(function () {
        WindowUtils.insertParam('t', token);
      });
    } else {
      WindowUtils.insertParam('t', token);
    }

  }

  handleFooterClick() {
    let newTab = window.open();
    if (newTab === null) {
      return;
    }
    newTab.location.href = 'https://www.smartyapp.io';
  }

  componentDidMount(): void {
    WindowUtils.preventBackButton();
  }

  render() {

    let jsx: JSX.Element = <React.Fragment>
      <Alert severity='error'>404 Not Found</Alert>
    </React.Fragment>;


    switch (this.props.clientAppConfig.formType) {

      case 'form':
        jsx = <React.Fragment>
          <CssBaseline />

          {!this.state.noHeader &&
            <React.Fragment>
              <Helmet>
                <title>{this.props.clientAppConfig.companyName} / {this.props.clientAppConfig.repName}</title>
              </Helmet>
              <Header clientAppConfig={this.props.clientAppConfig} ></Header>
            </React.Fragment>
          }

          <main className={this.props.classes.layout}>

            <Paper elevation={0} className={this.props.classes.paper}>
              <FundingForm
                loading={this.state.loading}
                formToken={this.state.formToken}
                clientAppConfig={this.props.clientAppConfig}
                onFormTokenUpdate={this.handleFormTokenUpdate}
                onValidTokens={this.storeTokens}
                onInvalidTokens={this.invalidateTokens}></FundingForm>
            </Paper>


          </main>
          <Notifier></Notifier>
          <Loader></Loader>
          <InviteOwnerForm token={this.state.formToken}></InviteOwnerForm>
        </React.Fragment>;
        break;
      case 'ppp':
        jsx = <React.Fragment>
          <CssBaseline />

          <Header clientAppConfig={this.props.clientAppConfig} ></Header>
          <main className={this.props.classes.layout}>

            <Paper elevation={0} className={this.props.classes.paper}>
              <PPPFundingForm formToken={this.state.formToken}
                onFormTokenUpdate={this.handleFormTokenUpdate}></PPPFundingForm>
            </Paper>
          </main>
          <Notifier></Notifier>
          <Loader></Loader>
        </React.Fragment>;
        break;
      case 'bank_connect':
        if (this.state.formToken !== 'new') {
          jsx = <React.Fragment>
            <CssBaseline />
            <Header clientAppConfig={this.props.clientAppConfig} ></Header>
            <main className={this.props.classes.layout}>
              <Paper elevation={0} className={this.props.classes.paper}>
                <BankConnectForm
                  formToken={this.state.formToken}
                  clientAppConfig={this.props.clientAppConfig}
                  onValidTokens={this.storeTokens}
                  onInvalidTokens={this.invalidateTokens}
                ></BankConnectForm>
              </Paper>
            </main>
            <Notifier></Notifier>
            <Loader></Loader>
          </React.Fragment>
        }

        break;

    }

    return <React.Fragment>

      {jsx}

      <BottomNavigation showLabels={true}>
        <BottomNavigationAction onClick={this.handleFooterClick} label="Powered by SmartyApp"></BottomNavigationAction>
      </BottomNavigation>

      <div>
        <table style={{
          margin: '0 auto'
        }} title="Click to Verify - This site chose DigiCert SSL for secure e-commerce and confidential communications.">
          <tbody>
            <tr><td style={{
              width: "135"
            }} align="center" valign="top">
              <script type="text/javascript" src="https://seal.websecurity.norton.com/getseal?host_name=demo.smartyapp.io&size=XS&use_flash=NO&use_transparent=No&lang=en"></script>
            </td></tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>;

  }
}

export default withStyles(styles, { withTheme: true })(App);