import React from "react";
import { EachOwnerFormInfo } from "../OwnerInfoForm";

import { Grid, Theme, createStyles, WithStyles, withStyles, Button, Typography, FormGroup, FormControlLabel, Checkbox, LinearProgress } from "@material-ui/core";
import { FormInputPropertiesInterface } from "../../../../util/ui/form/FormInputProperties";
import { InputData } from "../../../../util/ui/form/InputData";
import BasicInput from "../../../../util/ui/form/inputs/BasicInput";
import { ErrorInfo } from "../../../../util/ui/form/ErrorInfo";
import validator from 'validator';
import { GuessConfirmed } from "../GuessConfirmedEnum";
import SmartyAddressInput from "../../../../util/ui/form/inputs/SmartyAddressInput";
import { InputInfo } from "../../../../util/ui/form/AbstractBasicInput";
import PhoneInput from "../../../../util/ui/form/inputs/PhoneInput";
import StringUtils from "../../../../util/StringUtils";
import SSNInput from "../../../../util/ui/form/inputs/SSNInput";
import OtherSelect from "../../../../util/ui/form/OtherSelect";
import { businessTitleOptionsMerchantForm, useOfProceedsOptions } from "../../../../util/SelectOptions";
import CurrencyInput from "../../../../util/ui/form/inputs/CurrencyInput";
import NativeDatepicker from "../../../../util/ui/form/inputs/NativeDatepicker";
import { ClientAppConfig } from "../../../../util/AppUtil";

export enum OwnerFormInputNames {

    ownershipPercent = 'ownershipPercent',
    socialSecNum = 'socialSecNum',
    emailAddress = 'emailAddress',
    homeZip = 'homeZip',
    mobilePhone = 'mobilePhone',
    dob = 'dob',
    firstName = 'firstName',
    lastName = 'lastName',
    address = 'address',
    businessTitle = 'businessTitle',
    homeState = 'homeState',
    homeCity = 'homeCity'
}

export const FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {
    'emailAddress': {
        name: OwnerFormInputNames.emailAddress,
        label: 'Email Address',
        autofill: OwnerFormInputNames.emailAddress,
        placeholder: 'john@doe.com',
        required: true,
        type: 'email',
        inputMode: "email"
    },
    'mobilePhone': {
        name: OwnerFormInputNames.mobilePhone,
        label: 'Mobile Phone',
        autofill: OwnerFormInputNames.mobilePhone,
        placeholder: '(999) 999-9999',
        required: true,
        type: 'phone',
        inputMode: 'numeric'
    },
    'homeZip': {
        name: OwnerFormInputNames.homeZip,
        label: 'Zip Code',
        autofill: OwnerFormInputNames.homeZip,
        placeholder: '10001',
        required: true,
        type: 'text',
        inputMode: 'numeric',
        maxLength: 5
    },
    'homeCity': {
        name: OwnerFormInputNames.homeCity,
        label: 'City',
        autofill: OwnerFormInputNames.homeCity,
        placeholder: 'New York City',
        required: true,
        type: 'text'
    },
    'homeState': {
        name: OwnerFormInputNames.homeState,
        label: 'State',
        autofill: OwnerFormInputNames.homeZip,
        placeholder: 'NY',
        required: true,
        type: 'text'
    },
    'dob': {
        name: OwnerFormInputNames.dob,
        label: 'Date of Birth',
        autofill: OwnerFormInputNames.dob,
        placeholder: '01/01/1969',
        required: true,
        type: 'date'
    },
    'firstName': {
        name: OwnerFormInputNames.firstName,
        label: 'First Name',
        autofill: OwnerFormInputNames.firstName,
        placeholder: 'John',
        required: true,
        type: 'text'
    },
    'lastName': {
        name: OwnerFormInputNames.lastName,
        label: 'Last Name',
        autofill: OwnerFormInputNames.lastName,
        placeholder: 'Smith',
        required: true,
        type: 'text'
    },
    'address': {
        name: OwnerFormInputNames.address,
        label: 'Home Address',
        autofill: OwnerFormInputNames.address,
        placeholder: '123 Main st',
        required: true,
        type: 'text'
    },
    'businessTitle': {
        name: OwnerFormInputNames.businessTitle,
        label: 'Business Title',
        autofill: OwnerFormInputNames.businessTitle,
        placeholder: 'Title',
        required: true,
        type: 'text'
    },
    'socialSecNum': {
        name: OwnerFormInputNames.socialSecNum,
        label: 'Social Security Number',
        autofill: OwnerFormInputNames.socialSecNum,
        placeholder: '999-88-9999',
        required: true,
        type: 'phone',
        inputMode: 'numeric'
    },
    'ownershipPercent': {
        name: OwnerFormInputNames.ownershipPercent,
        label: 'Ownership Percent',
        autofill: OwnerFormInputNames.ownershipPercent,
        placeholder: '100%',
        required: true,
        type: 'number',
        min: 1,
        max: 100,
        inputMode: 'numeric'
    },
    'amountRequested': {
        name: 'amountRequested',
        label: 'Amount Requested',
        autofill: 'amountRequested',
        placeholder: 'How much cash do you need?',
        required: true,
        type: 'number',
        inputMode: 'numeric'
    },
    'useOfProceeds': {
        name: 'useOfProceeds',
        label: 'Use of Funds',
        autofill: 'useOfProceeds',
        placeholder: 'Use of Funds',
        required: true,
        type: 'text'
    }
};

const styles = (theme: Theme) => createStyles({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    navigator: {

    },
    firstGuess: {
        // marginTop: theme.spacing(2),
        // marginLeft: theme.spacing(2),
        // textAlign: 'center',
        justifyContent: 'center'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center',
    },
});

export interface EachOwnerFormProps extends WithStyles<typeof styles> {

    /**
     * identifiers
     */
    ownerIndex: number;
    formToken: string;
    eachOwnerFormInfo: EachOwnerFormInfo;
    fullEdit: boolean;
    clientAppConfig: ClientAppConfig;

    onBasicInputChange(inputInfo: InputData): void;
    onAmountRequestedChange: (amountRequested: InputData) => void;
    onUseOfProceedsChange: (useOfProceeds: InputData) => void;
    onBackClick(): void;
    onContinueClick(): void;
    onFirstGuessConfirmClickYes(): void;
    onFirstGuessConfirmClickNo(): void
    handlePermissionToContactTermsChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

export class EachOwnerFormState {
    errorFields: Map<string, ErrorInfo> = new Map();
}


export enum EachOwnerInternalSteps {
    Start, ConfirmPersonalInfoGuess, BriefFollowup, FullFollowUp, Followup
}

class EachOwnerForm extends React.Component<EachOwnerFormProps, EachOwnerFormState> {

    private errorFields: Map<string, ErrorInfo> = new Map();

    constructor(props: EachOwnerFormProps) {
        super(props);

        this.state = new EachOwnerFormState();

        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);
        this.handleContinue = this.handleContinue.bind(this);

    }

    handleBasicInputChange(input: InputInfo, event?: React.ChangeEvent<HTMLInputElement>) {

        if (this.errorFields.has(input.name)) {
            this.errorFields.delete(input.name);
            this.setState({
                errorFields: this.errorFields
            });
        }


        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        switch (input.name) {
            case 'amountRequested':

                this.props.onAmountRequestedChange(inputData);
                break;
            case 'useOfProceeds':
                this.props.onUseOfProceedsChange(inputData);
                break;
            default:


                this.props.onBasicInputChange(inputData);
                break;
        }

    }

    handleContinue(event: React.TouchEvent | React.MouseEvent) {

        if (!this.validateFields()) {
            return;
        }

        this.props.onContinueClick();

    }

    getConfirmedFields(): string[] {

        let firstGuess = this.props.eachOwnerFormInfo.firstGuess;

        if (firstGuess.confirmed !== GuessConfirmed.Yes || this.props.fullEdit) {
            return [];
        }

        let confirmedFields = [];

        if (!StringUtils.isEmpty(firstGuess.resultInfo.firstName)) {
            confirmedFields.push(OwnerFormInputNames.firstName);
        }

        if (!StringUtils.isEmpty(firstGuess.resultInfo.lastName)) {
            confirmedFields.push(OwnerFormInputNames.lastName);
        }

        if (!StringUtils.isEmpty(firstGuess.resultInfo.streetLine1)
            || !StringUtils.isEmpty(firstGuess.resultInfo.streetLine2)) {

            if (!StringUtils.isEmpty(firstGuess.resultInfo.city)) {
                confirmedFields.push(OwnerFormInputNames.homeZip);
                confirmedFields.push(OwnerFormInputNames.address);
            }

        }

        return confirmedFields;
    }


    getCurrentStep(): number {

        if (typeof this.props.eachOwnerFormInfo === 'undefined') {
            return EachOwnerInternalSteps.Start;
        }

        return this.props.eachOwnerFormInfo.getCurrentStep();

    }

    getRequiredFields(): string[] {
        let required: string[] = [];
        switch (this.getCurrentStep()) {

            case EachOwnerInternalSteps.Start:

                required.push(OwnerFormInputNames.emailAddress);
                required.push(OwnerFormInputNames.homeZip);
                required.push(OwnerFormInputNames.mobilePhone);

                break;

            case EachOwnerInternalSteps.Followup:

                required.push(OwnerFormInputNames.firstName);
                required.push(OwnerFormInputNames.lastName);
                required.push(OwnerFormInputNames.address);
                required.push(OwnerFormInputNames.homeZip);
                required.push(OwnerFormInputNames.dob);
                required.push(OwnerFormInputNames.socialSecNum);
                required.push(OwnerFormInputNames.ownershipPercent);
                required.push(OwnerFormInputNames.businessTitle);

                break;
        }

        return required;
    }

    validateRequired(): boolean {

        let required = this.getRequiredFields();

        for (var i in required) {

            if (!this.props.eachOwnerFormInfo.ownerInfo.getDataByName(required[i]) || this.props.eachOwnerFormInfo.ownerInfo.getDataByName(required[i]).value === '') {
                this.errorFields.set(required[i], new ErrorInfo(true));
            }
        }

        switch (this.getCurrentStep()) {
            case EachOwnerInternalSteps.Start:

                if (this.props.ownerIndex === 0) {
                    if ('' === this.props.eachOwnerFormInfo.amountRequested.value ||
                        isNaN(parseFloat(this.props.eachOwnerFormInfo.amountRequested.value)) ||
                        (!isNaN(parseFloat(this.props.eachOwnerFormInfo.amountRequested.value)) && (parseFloat(this.props.eachOwnerFormInfo.amountRequested.value) === 0))
                    ) {
                        this.errorFields.set('amountRequested', new ErrorInfo(true));
                    }
                    let val = (StringUtils.nFormat(this.props.eachOwnerFormInfo.amountRequested.value));
                    if (val > 9999999999) {
                        this.errorFields.set('amountRequested', new ErrorInfo(true));
                    }
                    if (!validator.isCurrency(StringUtils.nFormat(this.props.eachOwnerFormInfo.amountRequested.value).toString())) {
                        this.errorFields.set('amountRequested', new ErrorInfo(true));
                    }


                    if ('' === this.props.eachOwnerFormInfo.useOfProceeds.value) {
                        this.errorFields.set('useOfProceeds', new ErrorInfo(true));
                    }
                }
                break;
        }

        this.setState({
            errorFields: this.errorFields
        });

        if (this.errorFields.size > 0) {
            return false;
        }

        return true;
    }


    validateFields(): boolean {

        this.errorFields = new Map();

        if (!this.validateRequired()) {
            return false;
        }

        switch (this.getCurrentStep()) {
            case EachOwnerInternalSteps.Start:

                if (!validator.isEmail(this.props.eachOwnerFormInfo.ownerInfo?.getDataByName(OwnerFormInputNames.emailAddress).value)) {
                    this.errorFields.set(OwnerFormInputNames.emailAddress, new ErrorInfo(true));
                }

                if (!validator.isPostalCode(this.props.eachOwnerFormInfo.ownerInfo?.getDataByName(OwnerFormInputNames.homeZip).value, 'US')) {
                    this.errorFields.set(OwnerFormInputNames.homeZip, new ErrorInfo(true));
                }

                if (!validator.isMobilePhone(this.props.eachOwnerFormInfo.ownerInfo?.getDataByName(OwnerFormInputNames.mobilePhone).value, 'en-US')) {
                    this.errorFields.set(OwnerFormInputNames.mobilePhone, new ErrorInfo(true));
                }

                break;
            case EachOwnerInternalSteps.Followup:

                if (!validator.isNumeric(this.props.eachOwnerFormInfo.ownerInfo?.getDataByName(OwnerFormInputNames.ownershipPercent).value)) {
                    this.errorFields.set(OwnerFormInputNames.ownershipPercent, new ErrorInfo(true));
                }

                if (validator.toFloat(this.props.eachOwnerFormInfo.ownerInfo?.getDataByName(OwnerFormInputNames.ownershipPercent).value) > 100) {
                    this.errorFields.set(OwnerFormInputNames.ownershipPercent, new ErrorInfo(true));
                }

                if (validator.toFloat(this.props.eachOwnerFormInfo.ownerInfo?.getDataByName(OwnerFormInputNames.ownershipPercent).value) < 1) {
                    this.errorFields.set(OwnerFormInputNames.ownershipPercent, new ErrorInfo(true));
                }

                if (validator.toDate(this.props.eachOwnerFormInfo.ownerInfo?.getDataByName(OwnerFormInputNames.dob).value) === null) {
                    this.errorFields.set(OwnerFormInputNames.dob, new ErrorInfo(true));
                }

                break;

        }

        this.setState({
            errorFields: this.errorFields
        });

        if (this.errorFields.size > 0) {
            return false;
        }

        return true;
    }

    getStartJSX() {

        let header: JSX.Element = <React.Fragment></React.Fragment>;
        let firstOwnerJSX: JSX.Element = <React.Fragment></React.Fragment>;
        let emailProps: FormInputPropertiesInterface = FORM_PROPERTY_LIST[OwnerFormInputNames.emailAddress];
        if (this.props.ownerIndex === 0) {
            firstOwnerJSX =
                <React.Fragment>

                    <Grid item xs={12} >

                        <CurrencyInput
                            inputProps={FORM_PROPERTY_LIST['amountRequested']}
                            error={this.errorFields.get('amountRequested')?.error}
                            onInputChange={this.handleBasicInputChange}
                            value={this.props.eachOwnerFormInfo.amountRequested.value || ''}
                        >
                        </CurrencyInput>

                    </Grid>
                    <Grid item xs={12} >

                        <OtherSelect
                            inputProps={FORM_PROPERTY_LIST['useOfProceeds']}
                            error={this.errorFields.get('useOfProceeds')?.error}
                            onInputChange={this.handleBasicInputChange}
                            value={this.props.eachOwnerFormInfo.useOfProceeds.value || ''}
                            options={useOfProceedsOptions}
                        >
                        </OtherSelect>

                    </Grid>
                </React.Fragment>;

            header = <React.Fragment>
                <Grid item xs={12} >
                    <Typography variant="h6" gutterBottom>
                        Let's get started with your funding application.
                    </Typography>

                    <Typography variant="caption" gutterBottom>
                        Tell us something about yourself.
                    </Typography>
                    <br />
                </Grid>
            </React.Fragment>;

        } else {
            header = <React.Fragment>
                <Grid item xs={12} >
                    <Typography variant="h6" gutterBottom>
                        Going further with your funding application.
                    </Typography>

                    <Typography variant="caption" gutterBottom>
                        Tell us something about yourself.
                    </Typography>
                    <br />
                </Grid>
            </React.Fragment>;
            emailProps.readOnly = true;

        }


        let allowedSubmission = true;
        if (typeof this.props.eachOwnerFormInfo === 'undefined') {

            return <React.Fragment>
                <LinearProgress></LinearProgress>
            </React.Fragment>;

        } else {
            for (var i in this.props.clientAppConfig.permissionToContactTerms) {

                if (!this.props.eachOwnerFormInfo.permissionToContactTerms[i]) {
                    allowedSubmission = false;
                    break;
                }
            }
        }



        return <React.Fragment>
            <Grid container spacing={2}>

                {header}

                <Grid item xs={12} >
                    <BasicInput
                        inputProps={emailProps}
                        error={this.state.errorFields.get(OwnerFormInputNames.emailAddress)?.error}
                        onInputChange={this.handleBasicInputChange}
                        value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(OwnerFormInputNames.emailAddress)?.value}
                        formOrder={this.props.ownerIndex === 0 ? 1 : 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <BasicInput
                        inputProps={FORM_PROPERTY_LIST[OwnerFormInputNames.homeZip]}
                        error={this.state.errorFields.get(OwnerFormInputNames.homeZip)?.error}
                        onInputChange={this.handleBasicInputChange}
                        value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(OwnerFormInputNames.homeZip)?.value}
                        formOrder={this.props.ownerIndex === 0 ? 0 : 1}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PhoneInput
                        inputProps={FORM_PROPERTY_LIST[OwnerFormInputNames.mobilePhone]}
                        error={this.state.errorFields.get(OwnerFormInputNames.mobilePhone)?.error}
                        onInputChange={this.handleBasicInputChange}
                        value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(OwnerFormInputNames.mobilePhone)?.value}
                    />
                </Grid>
                {firstOwnerJSX}
                {this.props.clientAppConfig.permissionToContactTerms.length > 0 &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            {this.props.clientAppConfig.permissionToContactTerms.map((s, i) => {
                                return <React.Fragment key={i}>
                                    <FormGroup row>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    inputProps={{
                                                        'data-permission-to-contact-index': i
                                                    } as any}
                                                    checked={this.props.eachOwnerFormInfo.permissionToContactTerms[i]}
                                                    onChange={this.props.handlePermissionToContactTermsChange}
                                                    color="primary"
                                                />
                                            }
                                            label={<Typography variant='body2'>
                                                {s}
                                            </Typography>}
                                        />

                                    </FormGroup>
                                </React.Fragment>;
                            })}
                        </Grid>
                    </React.Fragment>
                }


                <Grid item xs={12} className={this.props.classes.navigator}>
                    <Button color="primary" variant="contained" onClick={this.handleContinue} fullWidth disabled={!allowedSubmission}>
                        Continue
                    </Button>
                </Grid>

            </Grid>
        </React.Fragment>
    }

    getConfirmPersonalInfoGuessJSX() {
        let firstGuess = this.props.eachOwnerFormInfo.firstGuess;
        return <React.Fragment>
            <Grid container spacing={2} className={this.props.classes.guessConfirmationContainer}>
                <Grid item xs={12} sm={6} >

                    <Grid container spacing={2} style={{ textAlign: 'center' }}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                                We found a match!
                            </Typography>

                            <Typography variant="body1" gutterBottom>
                                Is the information listed below correct?
                            </Typography>

                            <Typography variant="body2" gutterBottom>
                                {firstGuess.resultInfo.firstName} {firstGuess.resultInfo.lastName}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {firstGuess.resultInfo.streetLine1}
                                <br></br>
                                {firstGuess.resultInfo.streetLine2}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {firstGuess.resultInfo.city}, {firstGuess.resultInfo.state} {firstGuess.resultInfo.zip}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} >
                        <Grid item xs={6} >
                            <Button variant="contained" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.props.onFirstGuessConfirmClickNo}
                                fullWidth>
                                No
                            </Button>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Button color="primary" variant="contained" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.props.onFirstGuessConfirmClickYes}
                                fullWidth>
                                Yes
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

        </React.Fragment >
    }

    getFollowupJSX(): JSX.Element {

        let fields = [
            OwnerFormInputNames.firstName, OwnerFormInputNames.lastName,
            OwnerFormInputNames.address,
            OwnerFormInputNames.homeCity, OwnerFormInputNames.homeState,
            OwnerFormInputNames.homeZip,
            OwnerFormInputNames.dob, OwnerFormInputNames.socialSecNum,
            OwnerFormInputNames.ownershipPercent, OwnerFormInputNames.businessTitle
        ];

        let gridItems: JSX.Element[] = [];
        let o = 0;
        for (var i in fields) {

            let field = fields[i];

            if (this.getConfirmedFields().indexOf(field) >= 0) {
                // continue;
            }
            o++;
            switch (field) {

                case OwnerFormInputNames.address:

                    gridItems.push(<SmartyAddressInput
                        inputProps={FORM_PROPERTY_LIST[field]}
                        error={this.state.errorFields.get(field)?.error}
                        onInputChange={this.handleBasicInputChange}
                        value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(field)?.value || ''}
                        zipCode={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(OwnerFormInputNames.homeZip)?.value || ''}
                    ></SmartyAddressInput>);

                    break;

                case OwnerFormInputNames.socialSecNum:

                    gridItems.push(<SSNInput
                        inputProps={FORM_PROPERTY_LIST[field]}
                        error={this.state.errorFields.get(field)?.error}
                        onInputChange={this.handleBasicInputChange}
                        value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(field)?.value}
                        formOrder={o}
                    />
                    );
                    break;

                case OwnerFormInputNames.businessTitle:

                    gridItems.push(
                        <React.Fragment>
                            {/* <ButtonGroup orientation="vertical" size="large" aria-label="small outlined button group">
                                {businessTitleOptions.map(b =>
                                    <Button className={this.props.classes.titleOptionsBtn}>{b.value}</Button>
                                )}
                            </ButtonGroup> */}
                            <OtherSelect
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(field)?.value}
                                options={businessTitleOptionsMerchantForm}>
                            </OtherSelect>
                        </React.Fragment>
                    );

                    break;

                case OwnerFormInputNames.dob:

                    gridItems.push(<NativeDatepicker
                        inputProps={FORM_PROPERTY_LIST[field]}
                        error={this.state.errorFields.get(field)?.error}
                        onInputChange={this.handleBasicInputChange}
                        value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(field)?.value}
                        formOrder={o}
                    >
                    </NativeDatepicker>);
                    break;

                default:

                    gridItems.push(<BasicInput
                        inputProps={FORM_PROPERTY_LIST[field]}
                        error={this.state.errorFields.get(field)?.error}
                        onInputChange={this.handleBasicInputChange}
                        value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(field)?.value}
                        formOrder={o}
                    >
                    </BasicInput>);

                    break;
            }

        }


        let ownerNameArr = [];
        if (this.getConfirmedFields().indexOf(OwnerFormInputNames.firstName) >= 0) {
            ownerNameArr.push(this.props.eachOwnerFormInfo.ownerInfo.getDataByName(OwnerFormInputNames.firstName).value);
        }
        if (this.getConfirmedFields().indexOf(OwnerFormInputNames.lastName) >= 0) {
            ownerNameArr.push(this.props.eachOwnerFormInfo.ownerInfo.getDataByName(OwnerFormInputNames.lastName).value)
        }
        let ownerName = ownerNameArr.join(" ");
        if (this.props.ownerIndex !== 0) {
            if (ownerName === "") {
                ownerName = "the owner"
            }
        } else {
            ownerName = "yourself";
        }

        ownerName = "yourself";
        return <React.Fragment>
            <Grid container spacing={2}>
                <Typography variant="subtitle2" gutterBottom>
                    Tell us more about {ownerName}
                </Typography>

                <Grid item xs={12} >
                    <Grid container spacing={2}>

                        {gridItems.map((itemJSX, index) => (
                            <Grid key={index} item xs={12} sm={6} >
                                {itemJSX}
                            </Grid>
                        ))}

                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6} >

                            <Button variant="contained" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.props.onBackClick} fullWidth>
                                Back
                            </Button >
                        </Grid>
                        <Grid item xs={6} >

                            <Button variant="contained" color="primary" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.handleContinue} fullWidth >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </React.Fragment>;
    }

    render() {

        switch (this.getCurrentStep()) {
            case EachOwnerInternalSteps.Start:
                return this.getStartJSX();
            case EachOwnerInternalSteps.ConfirmPersonalInfoGuess:
                return this.getConfirmPersonalInfoGuessJSX();
            case EachOwnerInternalSteps.Followup:
                return this.getFollowupJSX();
            default:
                return <React.Fragment>
                    Error
                </React.Fragment>
        }
    }
}

export default withStyles(styles, { withTheme: true })(EachOwnerForm);