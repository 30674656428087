import * as React from "react";
import { Snackbar, SnackbarOrigin } from "@material-ui/core";

export interface NotifierProps {
    idPrefix: string;
}

class NotifierState {
    open: boolean = false;
    message: string = '';
    closeText: string = '';
    anchorOrigin: SnackbarOrigin = {
        vertical: 'bottom',
        horizontal: 'center'
    }
}

let openNotifierFn: (message: string, closeText: string, anchorOrigin?: SnackbarOrigin) => void;

export class Notifier extends React.Component<NotifierProps, NotifierState> {

    constructor(props: NotifierProps) {
        super(props);
        this.state = new NotifierState();
        this.openNotifier = this.openNotifier.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    }

    componentDidMount() {
        openNotifierFn = this.openNotifier;
    }

    openNotifier(message: string, closeText: string, anchorOrigin?: SnackbarOrigin): void {

        this.setState({
            open: true,
            message: message,
            closeText: closeText,
            anchorOrigin: typeof anchorOrigin === 'undefined' ? {
                horizontal: 'center',
                vertical: 'bottom'
            } : anchorOrigin
        });
    }

    handleSnackbarClose(): void {
        this.setState({
            open: false,
            message: ""
        });
    }

    static defaultProps = {

        idPrefix: "id-" + Math.round(Math.random() * 10000),

    };

    render() {
        let id: string = this.props.idPrefix + 'snackbar-message-id';
        const message = (
            <span
                id={id}
                dangerouslySetInnerHTML={{ __html: this.state.message }}
            />
        );
        return (
            <div>
                <Snackbar
                    anchorOrigin={this.state.anchorOrigin}
                    message={message}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                    open={this.state.open}
                    ContentProps={{
                        'aria-describedby': id,
                    }}
                />
            </div>
        );
    }
}

export function openNotifier(message: string, closeText: string = '', anchorOrigin?: SnackbarOrigin): void {
    openNotifierFn(message, closeText, anchorOrigin);
};