import React from "react";
import { Theme, createStyles, withTheme, withStyles, WithStyles, Button, LinearProgress, Dialog, DialogContent, Grid, List, ListItem, ListItemText, DialogActions, ListItemAvatar, Avatar, CardHeader, CardContent, Card, TextField, Hidden, Divider } from "@material-ui/core";
import { ErrorInfo } from "../../../util/ui/form/ErrorInfo";
import httpClient from "../../../util/HttpClient";

import { TransitionProps } from "@material-ui/core/transitions/transition";
import Slide from '@material-ui/core/Slide';
import { VpnLock, CloudUpload } from "@material-ui/icons";
import { openNotifier } from "../../../util/ui/dialog/notifier/Notifier";
import { openLoader } from "../../../util/ui/dialog/loader/Loader";
import { uploadSections, CategoryUpload, FileUploadCategoryNames, UploadSection } from "../../../util/DocumentUtils";

import { format, subMonths } from 'date-fns';
import { FORMAPI_BASE } from "../BaseUtil";
import UploadInput, { UploadedFile } from "../../../util/ui/form/UploadInput";
import { Institution } from "../../../util/lib/BankDefs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

interface BankConnectSite {

    bankName: string;
    providerId: string;
    providerAccountId: string;
    status: string;
    statusCode?: string;
    providerName: string;
    requestId: string;

}

const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    interstitialContainer: {
        justifyContent: 'center',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dialogContent: {
        [theme.breakpoints.down('sm')]: {
            width: theme.breakpoints.values.sm
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.breakpoints.values.xs
        },
        [theme.breakpoints.down('md')]: {
            width: theme.breakpoints.values.md
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.breakpoints.values.lg
        },
    }
});

interface BankInfoFormProps extends WithStyles<typeof styles> {
    onCompleteForm(): void;
    formToken: string;
    bankConnectEnabled: boolean;
    ownerIndex: number;
    urlToken: string;
    fileToken: string;
}

enum CurrentScreen {
    Loading, BankConnect, Upload
}

export class Connection {
    bankName: string = '';
    accountNumber: string = '';
    status: string = '';
    logo: string = '';
}

class BankInfoFormState {
    errorFields: Map<string, ErrorInfo> = new Map();
    currentScreen: number = CurrentScreen.Loading;
    agreed: boolean = false;
    linkingInitiated: boolean = false;
    bankDialogOpen: boolean = false;
    fullScreen: boolean = false;

    needNewConnection: boolean = false;
    yodleeToken: string = '';
    yodleeUrl: string = '';
    connections: Connection[] = [];
    banksList: Institution[] = [];
    selectedBank: null | Institution = null;
    bankSelectorDialogOpen: boolean = false;

    uploadedFiles: {
        [category: string]: CategoryUpload
    } = {};

    constructor() {
        this.uploadedFiles = {};
        for (var i in uploadSections) {
            this.uploadedFiles[uploadSections[i].category] = new CategoryUpload();
            this.uploadedFiles[uploadSections[i].category].name = uploadSections[i].category;
        }
    }
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class BankInfoForm extends React.Component<BankInfoFormProps, BankInfoFormState> {

    constructor(props: BankInfoFormProps) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.initiateLinkClick = this.initiateLinkClick.bind(this);
        this.handleAgreed = this.handleAgreed.bind(this);
        this.handleTnCClick = this.handleTnCClick.bind(this);
        this.scriptLoaded = this.scriptLoaded.bind(this);
        this.handleBankDialogClose = this.handleBankDialogClose.bind(this);
        this.handleBankDialogEnter = this.handleBankDialogEnter.bind(this);
        this.initiateUploadClick = this.initiateUploadClick.bind(this);

        this.handleFileDrop = this.handleFileDrop.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onYodleeExit = this.onYodleeExit.bind(this);
        this.onYodleeSuccess = this.onYodleeSuccess.bind(this);
        this.onYodleeEvent = this.onYodleeEvent.bind(this);
        this.onYodleeError = this.onYodleeError.bind(this);
        this.attachFromBankClick = this.attachFromBankClick.bind(this);
        this.handleBankSelect = this.handleBankSelect.bind(this);
        this.handleBankSelect2 = this.handleBankSelect2.bind(this);

        let state = new BankInfoFormState();
        this.state = state;

    }

    componentDidMount() {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/bankInfoForm').then((r) => {

            this.refreshData(r.data.data)

        }).catch(() => {
            openNotifier("Oops. We encountered some error. Please try re-loading the page");
        })

    }

    refreshData(data: any) {

        let needNewConnection = this.props.bankConnectEnabled && typeof data.newConnectionInfo.token !== 'undefined';
        //TBD: check
        // if ((data.files.length !== 'undefined' && data.files.length > 0)) {
        //     needNewConnection = false;
        // }
        let yodleeToken = needNewConnection ? data.newConnectionInfo.token : '';
        let yodleeUrl = needNewConnection ? data.newConnectionInfo.fastLinkURL : '';


        if (needNewConnection) {
            const script = document.createElement("script");
            script.src = "https://cdn.yodlee.com/fastlink/v3/initialize.js";
            script.async = true;
            script.onload = () => this.scriptLoaded();
            document.body.appendChild(script);

        }

        let currentScreen = CurrentScreen.BankConnect;
        if (needNewConnection || (data.connections !== null && data.connections.length > 0)) {
            currentScreen = CurrentScreen.BankConnect;
        } else {
            currentScreen = CurrentScreen.Upload;
        }

        this.setState({
            needNewConnection: needNewConnection,
            yodleeToken: yodleeToken,
            yodleeUrl: yodleeUrl,
            connections: data.connections === null ? [] : data.connections,
            currentScreen: currentScreen,
            banksList: data.banksList
        });

        this.refreshFiles(data.files);
    }

    componentDidUpdate() {
        if (this.state.needNewConnection && this.state.currentScreen === CurrentScreen.Loading) {
            if (window.fastlink !== 'undefined') {
                this.setState({
                    currentScreen: CurrentScreen.BankConnect
                });
            }
        }
    }

    scriptLoaded() {
        this.setState({
            currentScreen: CurrentScreen.BankConnect
        });

    }


    handleBackClick(): void {


        switch (this.state.currentScreen) {
            case CurrentScreen.BankConnect:

                break;
            case CurrentScreen.Upload:

                if (this.state.needNewConnection && this.props.bankConnectEnabled) {
                    this.setState({
                        currentScreen: CurrentScreen.BankConnect
                    })
                } else {

                }

                break;
        }


    }

    handleContinueClick(event: React.TouchEvent | React.MouseEvent): void {

        this.props.onCompleteForm();

    }

    initiateLinkClick() {

        this.setState({

            bankDialogOpen: true
        });
    }

    attachFromBankClick() {

        if (!this.state.selectedBank) {
            openNotifier("Please select a bank to proceed");

            return;
        }

        this.setState({
            bankDialogOpen: true
        })

    }

    handleBankSelect2(event: any, value: Institution | null) {

        this.setState({
            selectedBank: value
        })
    }

    handleBankSelect(event: React.ChangeEvent<HTMLInputElement>) {

        let found = null;

        for (let i in this.state.banksList) {
            let b = this.state.banksList[i];
            if (b.sourceId === event.currentTarget.value) {

                found = b;

                break;
            }
        }

        this.setState({
            selectedBank: found
        })

    }

    handleAgreed(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.currentTarget.checked) {
            this.setState({
                agreed: true
            });
        } else {
            this.setState({
                agreed: false
            });
        }
    }

    handleTnCClick(e: React.MouseEvent | React.TouchEvent) {
        e.preventDefault();
    }

    handleBankDialogClose() {
        this.setState({
            bankDialogOpen: false
        });
    }

    saveBankConnectSite(site: BankConnectSite) {

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken + '/bank-connect', site)
            .then((r) => {
                if (r.data.data.length > 0) {
                    this.setState({
                        connections: r.data.data
                    })
                }
            }).finally(() => {
                openLoader(false);
            });

    }

    initiateUploadClick(event: React.TouchEvent | React.MouseEvent): void {

        this.setState({
            currentScreen: CurrentScreen.Upload
        });

    }

    onYodleeExit(data: {
        action: string,
        sites: BankConnectSite[]
    }): void {
        console.log('onYodleeExit');
        console.log(data);
        this.setState({
            bankDialogOpen: false
        });

        if (typeof data.sites[0] === 'undefined') {
            openNotifier("Error! Please reload the page and try again");
            return;
        }

        let site = data.sites[0];

        this.saveBankConnectSite(site);

        if (site.status === 'FAILED') {
            openNotifier("Error! Please reload the page and try again");
            return;
        }

        openNotifier("Sucessfully Connected!");
    }

    onYodleeSuccess(data: any): void {
        console.log('onYodleeSuccess');
        console.log(data);

    }


    onYodleeError(data: any): void {
        console.log('onYodleeError');
        console.log(data);
    }


    onYodleeEvent(data: any): void {
        console.log('onYodleeEvent');
        console.log(data);
        if (typeof data.containerFinapp !== 'undefined') {
            let iframes = window.document.getElementsByTagName('iframe');
            if (typeof iframes[0] !== 'undefined') {
                iframes[0].style.minHeight = '1000px';
            }
            console.log(iframes);
        }
    }

    handleBankDialogEnter() {
        this.setState({
            linkingInitiated: true,
        });
        let now = new Date();
        let fromDate = format(subMonths(now, 3), 'yyyy-MM-dd');
        let fromDate12 = format(subMonths(now, 12), 'yyyy-MM-dd');
        let toDate = format(new Date(), 'yyyy-MM-dd');

        window.fastlink.open({
            fastLinkURL: this.state.yodleeUrl,
            accessToken: 'Bearer '+this.state.yodleeToken,
            params: {

                dataset: [
                    {
                        'name': 'BASIC_AGG_DATA',
                        'attribute': [
                            { 'name': 'ACCOUNT_DETAILS' },
                            { 'name': 'TRANSACTIONS', 'fromDate': fromDate12, 'toDate': toDate },
                            { 'name': 'BASIC_ACCOUNT_INFO' },
                            { 'name': 'HOLDINGS' }
                        ]
                    },

                    {
                        'name': 'DOCUMENT',
                        'attribute': [
                            {
                                'name': 'STATEMENTS',
                                'fromDate': fromDate,
                                'toDate': toDate
                            }
                        ]
                    }
                ],

                flow: 'add',

                providerId: this.state.selectedBank?.sourceId

            },
            onSuccess: this.onYodleeSuccess,
            onError: this.onYodleeError,
            onExit: this.onYodleeExit,
            onEvent: this.onYodleeEvent
        }, 'fastlink_container');

    }

    handleFileDrop(): void {

    }

    handleFileUpload(files: UploadedFile[]): void {

        if (files.length === 0) {
            return;
        }

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
            + '/files', files)
            .then((r) => {
                this.refreshFiles(r.data.data);
            })
            .catch((r) => {

            }).finally(() => {
                openLoader(false);
            })
    }

    refreshFiles(data: any): void {

        let files: {
            [category: string]: CategoryUpload
        } = {};

        for (var i in data) {
            let d = data[i];
            let c = CategoryUpload.fromData(d);
            files[d.name] = c;
        }

        this.setState({
            uploadedFiles: files
        });

    }


    getBankConnectFormRender(): JSX.Element {

        let needBankConnect = true;
        for (var i in this.state.connections) {
            if (this.state.connections[i].status !== 'FAILED') {
                needBankConnect = false;
            }
        }

        let bankLabel = <React.Fragment><VpnLock /> &nbsp; Attach From Bank</React.Fragment>;

        if (this.state.selectedBank) {
            bankLabel = <React.Fragment><VpnLock /> &nbsp; Attach From {this.state.selectedBank.name} </React.Fragment>;
        }

        return <Grid container spacing={2} >
            <Grid item sm={12}>
                <Card elevation={0} style={{

                    paddingBottom: '0'
                }}>
                    <CardHeader title="We have received your application"
                        subheaderTypographyProps={{
                            align: 'center'
                        }}
                        subheader=
                        {needBankConnect ?
                            <React.Fragment>
                                Please provide your business bank statements to complete your application.
                            </React.Fragment>
                            :
                            "We have connected with your below bank account!"
                        }
                        style={{
                            paddingTop: '0',
                            paddingBottom: '0'
                        }}
                        titleTypographyProps={{
                            align: 'center'
                        }}
                    >
                    </CardHeader>
                    <CardContent>

                        {this.state.connections?.length > 0 ?

                            <React.Fragment>
                                <Grid container spacing={2} className={this.props.classes.interstitialContainer}>
                                    <Grid item xs={12} sm={6}>

                                        <List title="Connected Bank Accounts">
                                            {this.state.connections.map((c, i) => {
                                                return <ListItem key={i}>
                                                    <ListItemAvatar>
                                                        {c.logo ?
                                                            <Avatar src={c.logo}>
                                                            </Avatar>
                                                            :
                                                            <Avatar>
                                                                <AccountBalanceIcon></AccountBalanceIcon>
                                                            </Avatar>
                                                        }
                                                    </ListItemAvatar>
                                                    <ListItemText primary={c.bankName} secondary={c.status !== 'FAILED' ? 'Connection: Good' : 'Connection: Failed'} />
                                                </ListItem>
                                            })}

                                        </List>

                                    </Grid>

                                </Grid>
                                {this.getMainNav()}
                            </React.Fragment>


                            :

                            <React.Fragment>


                                <Grid container spacing={2} className={this.props.classes.interstitialContainer}>

                                    <Grid item xs={12} sm={8}>
                                        {/* <Hidden smDown> */}
                                            <Autocomplete
                                                options={this.state.banksList}
                                                filterSelectedOptions
                                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                                                value={this.state.selectedBank}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Choose your Bank" variant="outlined" fullWidth />
                                                )}
                                                onChange={this.handleBankSelect2}
                                                renderOption={(option) => {
                                                    return (
                                                        <Grid container alignItems="center" spacing={2}>

                                                            <Grid item md={8} sm={12} xs={12}>
                                                                {option.name}
                                                            </Grid>

                                                            <Hidden smDown>
                                                                <Grid item sm={4} style={{ textAlign: 'right' }}>
                                                                    <img src={option.logo} alt={option.name} style={{ maxHeight: '30px', maxWidth: '100%' }} />
                                                                </Grid>
                                                            </Hidden>
                                                        </Grid>
                                                    );
                                                }}
                                                noOptionsText="That bank is not supported yet. Please check your spelling, or upload statement files"
                                            ></Autocomplete>
                                        {/* </Hidden> */}
                                        {/* <Hidden mdUp>

                                            <TextField
                                                onChange={this.handleBankSelect}
                                                label="Bank Selector"
                                                value={this.state.selectedBank?.sourceId}
                                                variant="outlined"
                                                fullWidth
                                                select
                                                SelectProps={{
                                                    native: true
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            >
                                                <option value=''>
                                                    Select Your Bank
                                                </option>
                                                {this.state.banksList.map(o =>
                                                    <option key={o.id} value={o.sourceId}>
                                                        {o.name}
                                                    </option>
                                                )}

                                            </TextField>

                                        </Hidden> */}

                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} className={this.props.classes.interstitialContainer}>

                                    <Grid item xs={12} sm={8}>
                                        {

                                        }
                                        <Button fullWidth color='primary' variant='contained' onClick={this.attachFromBankClick}>
                                            {bankLabel}
                                        </Button>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={2} className={this.props.classes.interstitialContainer}>

                                    <Grid item xs={8} sm={6}>
                                        <Divider />
                                    </Grid>

                                </Grid>
                                <Grid container spacing={2} className={this.props.classes.interstitialContainer}>
                                    <Grid item xs={12} sm={8} >
                                        <Button fullWidth color="default" variant='contained' onClick={this.initiateUploadClick}>
                                            <CloudUpload /> &nbsp;
                                            Bank not supported? Upload Statement Files
                                        </Button>
                                    </Grid>
                                </Grid>

                            </React.Fragment>
                        }
                        <Grid container spacing={2} className={this.props.classes.interstitialContainer}>

                            <Grid item xs={12} sm={6}>
                            </Grid>
                        </Grid>
                    </CardContent>

                </Card>
            </Grid>
        </Grid>;
    }

    getDocumentUploadRender(): JSX.Element {

        let currentUploadSections: UploadSection[] = [];

        for (var i in uploadSections) {
            if (FileUploadCategoryNames.bankStatement === uploadSections[i].category) {
                currentUploadSections.push(uploadSections[i]);
            }
        }

        return <Grid container spacing={2} >

            <Grid item xs={12}>
                <Card elevation={0}>
                    <CardContent >
                        {currentUploadSections.map((u, i) => {

                            return <UploadInput
                                key={i}
                                category={u.category}
                                label={u.label}
                                required={u.required}
                                fileListUploaded={this.state.uploadedFiles[u.category]?.getFilenames()}
                                error={false}
                                formToken={this.props.fileToken}
                                onFileupload={this.handleFileUpload}
                                urlToken={this.props.urlToken}
                            >

                            </UploadInput>
                        })}
                    </CardContent>
                </Card>
                {this.getMainNav()}
            </Grid>

        </Grid>;
    }

    getBankConnectRender(): JSX.Element {

        return <React.Fragment>
            {this.props.bankConnectEnabled ?
                this.getBankConnectFormRender()
                :
                <React.Fragment>

                </React.Fragment>
            }

        </React.Fragment>;
    }

    getMainNav(): JSX.Element {

        return <Card elevation={0}>
            <CardContent>
                <Grid container spacing={2} >
                    {this.props.bankConnectEnabled ?
                    <Grid item xs={6}>
                        <Button variant='contained' color='default' onClick={this.handleBackClick} fullWidth>
                            Back
                        </Button>
                    </Grid>:
                    <React.Fragment></React.Fragment>
                    }

                    <Grid item xs={this.props.bankConnectEnabled ? 6 : 12}>
                        <Button variant='contained' color='primary' onClick={this.handleContinueClick} fullWidth>
                            Continue
                    </Button>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>;
    }

    render() {

        let jsx = <LinearProgress></LinearProgress>;

        switch (this.state.currentScreen) {
            case CurrentScreen.Loading:
                jsx = <LinearProgress></LinearProgress>;
                break;
            case CurrentScreen.BankConnect:
                jsx = this.getBankConnectRender();
                break;
            case CurrentScreen.Upload:
                jsx = this.getDocumentUploadRender();
                break;
            default:
                jsx = <React.Fragment>
                    Error
                </React.Fragment>;
                break;
        }

        return <React.Fragment>
            {jsx}

            <Dialog
                open={this.state.bankDialogOpen} onEntered={this.handleBankDialogEnter} onClose={this.handleBankDialogClose} TransitionComponent={Transition}
                fullScreen>
                <DialogActions>
                    <Button autoFocus onClick={this.handleBankDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
                <DialogContent >
                    <div id='fastlink_container' style={{ height: '100%', minHeight: '550px', overflow: 'hidden' }}>

                    </div>
                </DialogContent>
            </Dialog>

        </React.Fragment>;
    }
}

export default withTheme(withStyles(styles)(BankInfoForm));